import Sobre from '../../Components/Sobre';
import ScrollToTop from '../../Components/ScrollToTop';

const SobreNos = () => {
    return (
        <>
            <ScrollToTop />
            <Sobre />
        </>
    )
}

export default SobreNos;